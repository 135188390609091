window.account = function () {
  let registrationForm = $(".register-form-content form");

  $('.register-form-content').on('click', ' .show-password', function (evt) {
    let target = $(evt.target);
    let inputPasssword = null;
    let icon = null;

    if (target.is("div")) {
      inputPasssword = target.siblings("input")[0];
      icon = target.children();
    } else if (target.is("i")) {
      inputPasssword = target.parent("div.input-group-prepend.show-password").siblings("input.form-control")[0];
      icon = target;
    }

    if (inputPasssword.type === "password") {
      inputPasssword.type = "text";
      icon.removeClass("fa-eye");
      icon.addClass("fa-eye-slash")
    } else {
      inputPasssword.type = "password";
      icon.removeClass("fa-eye-slash");
      icon.addClass("fa-eye");
    }

  });

  $('.label-input-simulate').on('click', function () {

    $('#inputFile').trigger('click');
    $('#inputFile').change(function (event) {

      let target = event.target;
      let _self = this;
      let _validFileExtensions = [".jpg", ".jpeg", ".png"];
      if (target.type == "file") {
        var sFileName = target.value;

        if (sFileName.length > 0) {
          var blnValid = false;
          for (var j = 0; j < _validFileExtensions.length; j++) {
            var sCurExtension = _validFileExtensions[j];
            if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
              blnValid = true;
              break;
            }
          }

          if (!blnValid) {
            alert(trans('frontend.the-format-is-not-allowed'));
            alert(trans('frontend.allowed-formats') + ': .jpg, .jpeg, .png ');
            target.value = "";
            return false;
          } else {
            let file = target.files[0];
            $('.label-input-simulate').text(file.name);
          }
        }
      }
      return true;
    });
  });

  $.validator.addMethod('password_validate', function (value) {
    var re = new RegExp("^(?=.*?[a-z])(?=.*?[0-9]).{6,}$");
    return re.test(value);
  }, trans('frontend.rules-of-password'));

  $.validator.addMethod('password_compare', function (value, element) {
    let password = registrationForm.find('.password')[0];
    if (password.value == value) {
      return true;
    }
    return false;
  }, trans('frontend.rules-of-password'));

  registrationForm.validate({
    rules: {
      name: "required",
      address: "required",
      postal_code: "required",
      email: {
        required: true,
        email: true
      },
      password: {
        required: true,
        password_validate: true,
        minlength: 8,
      },
      password_confirmation: {
        required: true,
        password_validate: true,
        minlength: 8,
        password_compare: true
      },
      "hidden-grecaptcha": {
        required: true,
        minlength: "255"
      }
      //accept_rgpd: "required",
    },
    messages: {
      name: trans('frontend.the-name-is-a-required-field'),
      address: trans('frontend.the-address-is-a-required-field'),
      postal_code: trans('frontend.the-postal-code-is-a-required-field'),
      email: {
        required: trans('frontend.the-email-is-a-required field'),
        email: trans('frontend.your-email-address-must-be-in-the-format-of-name@domain')
      },
      password: {
        required: trans('all.required_password'),
        minlength: trans('frontend.must-have-at-least-8-charactersn'),
      },
      password_confirmation: {
        required: trans('all.required_password_confirmation'),
        minlength: trans('frontend.must-have-at-least-8-charactersn'),
        password_compare: trans('frontend.must-have-the-same'),
      },
      // accept_rgpd:trans('frontend.the-accept-terms-is-a-required field'),
    },
    submitHandler:
      function (form, evt) {
        const recaptchaRegister = $(form).find('.g-recaptcha-response-register');
        console.log('sitekey', recaptchaRegister.data('sitekey'));

        grecaptcha.execute(recaptchaRegister.data('sitekey'))
          .then(function (token) {
            debugger;
            // Envie o formulário para o servidor
            recaptchaRegister.val(token);
            // Exemplo de envio de formulário usando jQuery
            form.submit();
          });
      },

    invalidHandler: function (evt, validator) {

      console.log(["validator", validator]);
      console.log(["registrationForm", registrationForm]);
      if (validator.numberOfInvalids() > 0) {                   // checks form for validity
        registrationForm.find('button.submitButton').prop('disabled', false);        // enables button

      } else {
        registrationForm.find('button.submitButton').prop('disabled', 'disabled');   // disables button
      }

      console.log('invalidHandler');
    }
  });

  $(".register-form-content").on("keyup blur click", function () { // fires on every keyup & blur

    let termsCheck = $("#accept_rgpd");
    let registrationButton = $(".register-form-content .submitButton")[0] || $(".register-form-content .submitButton")[1];

    if (termsCheck.prop('checked')) {
      registrationButton.removeAttribute('disabled');
    } else {
      registrationButton.setAttribute('disabled', 'disabled');
    }
  });

}
